<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="configConceptos"
          class="elevation-1"
          :search="searchConceptos"
        >
          <template v-slot:[`item.esAdicional`]="{ item }">
            <v-icon
              v-if="item.esAdicional"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.bonifica`]="{ item }">
            <v-icon
              v-if="item.bonifica"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.esInteres`]="{ item }">
            <v-icon
              v-if="item.esInteres"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:[`item.esPercIB`]="{ item }">
            <v-icon
              v-if="item.esPercIB"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col align-self="center">
                  <span class="primary--text">{{ titleConceptosTable }}</span>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="searchConceptos"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModalImpuesto(item)"
                >
                  mdi-file-percent-outline
                </v-icon>
              </template>
              <span>Ver impuestos</span>
            </v-tooltip>
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar concepto</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteConcepto(item.concId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar concepto</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEditConceptos"
      v-model="openModalEditConceptos"
      max-width="500px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditConcepto
        :conceptoObject="conceptoObject"
        @closeAndReload="closeAndReload"
        @openModalImpuestoSugerencia="openModalImpuestoSugerencia"
        @emitConcepto="emitConcepto"
      ></EditConcepto>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-if="openModalImpuestoConcep"
      v-model="openModalImpuestoConcep"
      max-width="80%"
      @keydown.esc="closeModalImpuestos"
      persistent
    >
      <Impuesto
        :conceptoObject="conceptoObject"
        @closeAndReloadImpuestos="closeAndReloadImpuestos"
        @closeModalImpuestos="closeModalImpuestos"
      ></Impuesto>
    </v-dialog>
    <v-dialog
      v-if="openModalNuevoImpuesto"
      v-model="openModalNuevoImpuesto"
      max-width="500px"
      @keydown.esc="closeModalNuevoImp"
      persistent
    >
      <EditImpuesto
        :impuestoObject="impuestoObject"
        :conceptoObject="conceptoObject"
        @closeAndReload="closeModalNuevoImp"
        @closeModalImpuestoSugerencia="closeModalImpuestoSugerencia"
      ></EditImpuesto>
    </v-dialog>
    <v-dialog
      v-if="openModalSugerencia"
      v-model="openModalSugerencia"
      max-width="45%"
      @keydown.esc="closeModalImpuestoSugerencia"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title justify="center">
            <v-spacer></v-spacer>
            <span class="pl-1 primary--text"
              >¿Desea crear un impuesto para este nuevo concepto?</span
            >
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="closeModalImpuestoSugerencia"
              class="d-flex  justify-center"
            >
              No
            </v-btn>
            <v-btn
              color="primary"
              @click="openModalNuevoImp"
              class="d-flex  justify-center"
            >
              Si
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditConcepto from "@/components/modules/cuotas/configuracion/EditConcepto.vue";
import EditImpuesto from "@/components/modules/cuotas/configuracion/EditImpuesto.vue";
import Impuesto from "@/components/modules/cuotas/configuracion/Impuestos.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConceptosApp",
  components: {
    PageHeader,
    DeleteDialog,
    EditConcepto,
    EditImpuesto,
    Impuesto,
    GoBackBtn,
    Ayuda
  },

  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    showDeleteModal: false,
    showDeleteModalImpuestos: false,
    searchConceptos: "",
    searchIcon: enums.icons.SEARCH,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.CONCEPTOS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    idToDelete: null,
    nuevoConcepto: {},
    routeToGo: "DevengamientosCobranzas",
    title: enums.titles.CONCEPTOS_APP,
    titleDelete: enums.titles.CONCEPTOS_APP_DELETE,
    rules: rules,
    impuestoObject: { valor: 0 },
    configConceptos: [],
    headers: [
      {
        text: "Concepto",
        align: "start",
        value: "concNom"
      },
      {
        text: "Adicional",
        value: "esAdicional",
        align: "center"
      },
      {
        text: "Bonificación",
        value: "bonifica",
        align: "center"
      },
      {
        text: "Interés",
        value: "esInteres",
        align: "center"
      },
      {
        text: "Percepción IIBB",
        value: "esPercIB",
        align: "center"
      },
      {
        text: "Jurisdicción IIBB",
        value: "juribNom",
        align: "center"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEditConceptos: false,
    titleConceptosTable: "Conceptos",
    conceptoObject: {},
    //////impuestos
    openModalImpuestoConcep: false,
    openModalNuevoImpuesto: false,
    openModalSugerencia: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadConceptos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getConceptos: "configuracion/getConceptos",
      deleteConfigConcepto: "configuracion/deleteConcepto",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_CONCEPTO:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONCEPTO:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_CONCEPTO:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadConceptos() {
      const response = await this.getConceptos();
      this.configConceptos = response;
    },
    deleteConcepto(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      const response = await this.deleteConfigConcepto({
        concId: this.idToDelete
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadConceptos();
      }
    },
    openModal(item) {
      this.openModalEditConceptos = true;
      this.conceptoObject = item;
    },

    emitConcepto(item) {
      this.conceptoObject = item;
    },

    openModalNuevoImp() {
      this.openModalNuevoImpuesto = true;
    },
    closeModalNuevoImp() {
      this.openModalNuevoImpuesto = false;
      this.nuevoConcepto = {};
    },
    openModalImpuestoSugerencia() {
      this.openModalSugerencia = true;
    },
    closeModalImpuestoSugerencia() {
      this.openModalSugerencia = false;
    },
    openModalImpuesto(item) {
      this.openModalImpuestoConcep = true;
      this.conceptoObject = item;
    },
    closeModal() {
      this.openModalEditConceptos = false;
    },
    closeModalImpuestos() {
      this.openModalImpuestoConcep = false;
    },
    closeAndReload() {
      this.openModalEditConceptos = false;
      this.loadConceptos();
    },
    closeAndReloadImpuestos() {
      this.openModalImpuestoConcep = false;
      this.loadConceptos();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
