<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form2"
          form="form2"
          id="form2"
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <!-- CONCEPTO -->
              <v-text-field
                v-model="conceptoObject.concNom"
                label="Concepto"
                outlined
                clearable
                filled
                disabled
                readonly
                class="theme--light.v-input input"
                dense
                :rules="rules.required"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-select
                v-model="impuestoSelected"
                :items="impuestoCombo"
                item-text="value"
                item-value="id"
                label="Impuesto"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-select
                v-model="modoApliSelected"
                :items="modoApliCombo"
                label="Modo aplicación"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-select
                v-if="!hidden"
                v-model="condIvaMultiSelected"
                :items="listCondIva"
                outlined
                clearable
                dense
                label="Condición de IVA"
                multiple
                item-text="value"
                item-value="id"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="tipoCondIvaToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="
                          condIvaMultiSelected.length > 0 ? 'primary' : ''
                        "
                      >
                        {{ multiselectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.value }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ condIvaMultiSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-select
                v-if="hidden"
                v-model="condIvaSelected"
                :items="listCondIva"
                item-text="value"
                item-value="id"
                label="Condición de IVA"
                outlined
                clearable
                dense
                :rules="rules.required"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoDesde"
                label="Período desde"
                dense
                outlined
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                v-mask="'######'"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)], rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="periodoHasta"
                label="Período hasta"
                dense
                outlined
                v-mask="'######'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="
                  rules.required.concat(
                    [rules.validDateRange(periodoDesde, periodoHasta)], rules.periodoYyyyMm
                  )
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                v-model="porcentaje"
                :append-icon="porcentajeIcon"
                hint="Porcentaje"
                dense
                outlined
                onkeypress="return (event.charCode >= 46 && event.charCode <= 57)"
                :rules="validateFormat()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pb-0">
              <v-text-field
                type="number"
                v-model="valor"
                label="Valor"
                dense
                disabled
                filled
                readonly
                class="theme--light.v-input input"
                outlined
                :rules="rules.required"
                onkeydown="return (event.keyCode !== 69 && 
                        event.keyCode !== 109 && 
                        event.keyCode !== 107 &&
                        event.keyCode !== 188 &&
                        event.keyCode !== 190)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form2"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "EditConcepto",
  props: {
    impuestoObject: {
      type: Object,
      default: null
    },
    conceptoObject: {
      type: Object,
      default: null
    }
  },
  directives: {mask},
  data: () => ({
    isFormValid: false,
    descripcion: "",
    formEditTitle: enums.titles.EDIT_IMPUESTO,
    newTitle: enums.titles.NUEVO_IMPUESTO,
    rules: rules,
    calendarIcon: enums.icons.CALENDAR,
    porcentajeIcon: enums.icons.PERCENT_OUTLINE,
    esAdicional: false,
    bonificacion: false,
    esInteres: false,
    percepcion: false,
    conceptoSelected: null,
    conceptoCombo: [],
    impuestoSelected: null,
    impuestoCombo: [],
    condIvaMultiSelected: [],
    listCondIva: [],
    condIvaSelected: null,
    hidden: false,
    menu1: false,
    menu2: false,
    modoApliCombo: ["P"],
    modoApliSelected: null,
    periodoDesde: null,
    periodoHasta: null,
    valor: 0,
    porcentaje: null
  }),
  created() {
    if (this.impuestoObject.valor !== 0) {
      this.hidden = true;
      this.setImpuesto();
    } else {
      this.newImpuesto();
    }
    this.setConceptoSelect();
    this.setImpuestoSelect();
    this.setTiposIvaSelect();
  },
  watch: {
    isFormValid() {
      if (this.condIvaMultiSelected.length === 0 && !this.impuestoObject.concImpId) {
        this.isFormValid = false;
      }
    },
    porcentaje() {
      this.calcularValorPorcentaje(this.porcentaje);
    }
  },
  computed: {
    multiselectIcon() {
      if (this.selectAlltiposCondIva) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAlltiposCondIva() {
      return (
        this.condIvaMultiSelected &&
        this.condIvaMultiSelected.length === this.listCondIva.length
      );
    }
  },
  methods: {
    ...mapActions({
      getConceptos: "configuracion/getConceptos",
      getImpuestos: "configuracion/getImpuestos",
      postImpuesto: "configuracion/postImpuesto",
      getTiposIva: "afiliaciones/getTiposIva",
      setAlert: "user/setAlert",
      getImpuestosList: "configuracion/getImpuestosList"
    }),
    async setConceptoSelect() {
      const response = await this.getConceptos();
      this.conceptoCombo = response;
    },
    async setImpuestoSelect() {
      const response = await this.getImpuestosList();
      this.impuestoCombo = response;
    },
    async setTiposIvaSelect() {
      const response = await this.getTiposIva();
      this.listCondIva = response;
    },
    async setImpuesto() {
      this.conceptoSelected = this.impuestoObject.concId;
      this.impuestoSelected = this.impuestoObject.impConcId;
      this.condIvaSelected = this.impuestoObject.ivaId;
      this.periodoDesde = this.impuestoObject.periodoDesde;
      this.periodoHasta = this.impuestoObject.periodoHasta;
      this.modoApliSelected = this.impuestoObject.modo;
      this.valor = this.impuestoObject.valor;
      let porcentajeVar = this.valor * 100;
      porcentajeVar = porcentajeVar.toString();
      if (porcentajeVar.length > 5) {

        porcentajeVar = porcentajeVar.slice(0, 5);
        
      }
      porcentajeVar = +porcentajeVar
      this.porcentaje = porcentajeVar;
      
    },
    async newImpuesto() {
      this.formEditTitle = this.newTitle;
      this.porcentaje = 0;
      this.valor = 0;
    },
    async save() {
      const impuesto = this.impuestoCombo.find(x => x.id == this.impuestoSelected);
      const data = {
        concImpId: this.impuestoObject.concImpId,
        concId: this.conceptoObject.concId,
        impConcId: impuesto.id,
        impConcNom: impuesto.concNom,
        ivaId: this.condIvaSelected,
        listIvaId: this.condIvaMultiSelected,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        modo: this.modoApliSelected,
        valor: this.valor
      };
      const res = await this.postImpuesto(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    calcularValorPorcentaje(numero) {
      this.valor = numero / 100;
    },
    tipoCondIvaToggle() {
      this.$nextTick(() => {
        if (this.selectAlltiposCondIva) {
          this.condIvaMultiSelected = [];
        } else {
          this.condIvaMultiSelected = this.listCondIva;
          this.condIvaMultiSelected = this.listCondIva.map(x => x.id);
        }
      });
    },
    closeModal() {
      this.$emit("closeAndReload");
      this.$emit("closeModalImpuestoSugerencia");
    },
    // Función que valida el formato de el campo porcentaje.
    validateFormat() {
      //Variable que indica cantidad de "."
      let dotCount = 0;
      //Variable que indica cantidad de caracteres decimales (despues de la coma)
      let afterComa = 0;
      //Convierto a String el porcentaje
      let arrayString = this.porcentaje.toString();
      //Recorro String de porcentaje
      for (let index = 0; index < arrayString.length; index++) {
        //Asigno la variable temporal
        const element = arrayString[index];
        //Validacion que verifica si hay un caracter despues de la coma, o punto.
        if (dotCount == 1) {
          //Aumento el 'afterComa' porque estoy ubicado despues de una coma o punto
          afterComa++;
        }
        //Valido que existan 2 numero maximos antes de la coma, o punto
        if (dotCount == 0 && index > 2 && element != "." && afterComa == 0) {
          return ["Formato incorrecto"];
        }
        //Si el elemento es un '.' aumento la cantidad de puntos.
        if (element == ".") {
          dotCount++;
          //Valido que si el punto no este ubicado en el inicio o en un indice mayor a 3
          if (index == 0 || index > 3) {
            return ["Formato incorrecto"];
          }
        }
        //Valido que no haya mas de un caracter despues de la coma.
        if (afterComa > 1) {
          return ["Formato incorrecto"];
        }
        //Valido que el elemento no sea un '/' o que la cantidad de '.' sea mayor a uno.
        if (element == "/" || dotCount > 1) {
          return ["Formato incorrecto"];
        }
      }
      return [];
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}

::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
