<template>
<v-card>
    <v-container>
        <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-data-table
        :headers="headersImpuestos"
        :items="configImpuestos"
        class="elevation-1 mt-0"
        :search="searchImpuestos"
        dense
      >
        <template v-slot:top v-if="canCreateImpuestos">
          <v-toolbar flat>
            <v-row>
              <!-- <v-col align-self="center">
                <span class="primary--text">{{ titleImpuestosTable }}</span>
              </v-col> -->
              <v-col cols="10">
                <v-text-field
                  v-model="searchImpuestos"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end" v-if="canCreateImpuestos">
                <v-btn
                  color="primary"
                  @click="openModalImpuesto()"
                  class="to-right"
                >
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEditImpuestos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModalImpuesto(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar impuesto</span>
          </v-tooltip>
          <v-tooltip left v-if="canDeleteImpuestos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteImpuesto(item.concImpId)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar impuesto</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-if="openModalEditImpuestos"
        v-model="openModalEditImpuestos"
        max-width="500px"
        @keydown.esc="closeModal"
        persistent
      >
        <EditImpuesto
          :impuestoObject="impuestoObject"
          :conceptoObject="conceptoObject"
          @closeAndReload="closeAndReload"
        ></EditImpuesto>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDeleteImpuesto"
        :openDelete.sync="showDeleteModalImpuestos"
        @onDeleteItem="confirmDeleteImpuesto()"
      />
  <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="mt-2" color="primary" @click="closeModalImpuestos"> Cerrar </v-btn>
      </v-card-actions>
  </v-container>
    </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditImpuesto from "@/components/modules/cuotas/configuracion/EditImpuesto.vue";
export default {
  name: "Impuestos",
  components: {
    DeleteDialog,
    EditImpuesto
  },
  props: {
    conceptoObject: {
      type: Object,
      required: false
    }
  },

  data: () => ({
    editIcon: enums.icons.EDIT,
    enums: enums,
    deleteIcon: enums.icons.DELETE,
    showDeleteModalImpuestos: false,
    searchIcon: enums.icons.SEARCH,
    formEditTitle: enums.titles.IMPUESTOS,
    checkIcon: enums.icons.CHECK_OUTLINE,
    rules: rules,
    configImpuestos: [],
    searchImpuestos: "",
    titleImpuestosTable: "Impuestos",
    headersImpuestos: [
      {
        text: "Impuesto",
        value: "impConcNom"
      },
      {
        text: "Condición de IVA",
        value: "ivaNom"
      },
      {
        text: "Período desde",
        value: "periodoDesde"
      },
      {
        text: "Período hasta",
        value: "periodoHasta"
      },
      {
        text: "Modo aplicación",
        value: "modo"
      },
      {
        text: "Valor",
        value: "valor"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    canCreateImpuestos: false,
    canEditImpuestos: false,
    canDeleteImpuestos: false,
    titleDeleteImpuesto: enums.titles.DELETE_IMPUESTO,
    openModalEditImpuestos: false,
    idToDeleteImpuesto: null,
    allowedActions: null,
    impuestoObject: {valor: 0}
  }),

  created() {
      this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadImpuestos();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      deleteConfigImpuesto: "configuracion/deleteImpuesto",
      getImpuestos: "configuracion/getImpuestos",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_IMPUESTO:
            this.canDeleteImpuestos = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_IMPUESTO:
            this.canEditImpuestos = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_IMPUESTO:
            this.canCreateImpuestos = true;
            break;
          default:
            break;
        }
      });
    },
    async loadImpuestos() {
      const response = await this.getImpuestos(this.conceptoObject.concId);
      this.configImpuestos = response;
    },
    deleteImpuesto(id) {
      this.showDeleteModalImpuestos = true;
      this.idToDeleteImpuesto = id;
    },
    async confirmDeleteImpuesto() {
      const response = await this.deleteConfigImpuesto({
        concImpId: this.idToDeleteImpuesto
      });
      if (response.status === 200) {
        this.showDeleteModalImpuestos = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadImpuestos();
      }
    },
    openModalImpuesto(item) {
      this.openModalEditImpuestos = true;
      if (item){this.impuestoObject = item}
    },
    closeModal() {
      this.openModalEditImpuestos = false;
    },
    closeModalImpuestos() {
      this.$emit("closeModalImpuestos");
    },
    closeAndReload() {
      this.openModalEditImpuestos = false;
      this.impuestoObject = {valor: 0};
      this.loadImpuestos();
    }
  }
};
</script>

<style scoped></style>
